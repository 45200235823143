import PropTypes from 'prop-types';
import styles from './InteractQuiz.module.css';

const propTypes = {
  quizId: PropTypes.string.isRequired,
  disableCoverPage: PropTypes.bool,
};

const defaultProps = {
  disableCoverPage: false,
};

export default function InteractQuiz({ quizId, disableCoverPage }) {
  return (
    <div
      className={styles.container}
      dangerouslySetInnerHTML={{
        __html: `<div id="interact-${quizId}"></div>
                  <script>
                  var app_${quizId};
                  (function(d, t){var s=d.createElement(t),
                    options={
                        "appId":"${quizId}",
                        "width":"800",
                        "height":"800",
                        "async":true,
                        "host":"quiz.tryinteract.com",
                        "no_cover":${disableCoverPage},
                        "redirect_host":true};
                        s.src='https://i.tryinteract.com/embed/app.js';
                        s.onload=s.onreadystatechange=function(){
                            var rs=this.readyState;
                            if(rs)if(rs!='complete')
                            if(rs!='loaded')return;
                            try{app_${quizId}=new InteractApp();
                            app_${quizId}.initialize(options);
                            app_${quizId}.display();}
                            catch(e){}};
                            var scr=d.getElementsByTagName(t)[0],
                            par=scr.parentNode;par.insertBefore(s,scr);})
                            (document,'script');
                            </script>
`,
      }}
    />
  );
}

InteractQuiz.propTypes = propTypes;
InteractQuiz.defaultProps = defaultProps;
