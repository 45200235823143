import Image from './Image';
import styles from './ImageOnly.module.css';
import PropTypes from 'prop-types';

const propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        image: PropTypes.object.isRequired,
        imageAlt: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
};

const defaultProps = {};

export default function ImageOnly({ images }) {
  return (
    <div className={styles.container}>
      <div className={styles.images}>
        {images.map(({ item }, index) => {
          return (
            <div className={styles.image_wrapper} key={index}>
              <Image src={item.image} alt={item.imageAlt} placeholder="blur" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

ImageOnly.propTypes = propTypes;
ImageOnly.defaultProps = defaultProps;
