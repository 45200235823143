import PropTypes from 'prop-types';
import styles from './UpsellMiniCart.module.css';
import UpsellForm from './UpsellForm';
import { useFunnelData } from 'utils/funnel-data-context';
import { usePreviewData } from 'utils/preview-data-context';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { missingPageOffer } from 'utils/error';
import { useEffect } from 'react';
import { buildCssVar } from 'utils/style-override';
import formatCurrency from 'utils/format-currency';
import useLocalCurrency from 'utils/useLocalCurrency';
import { useShippingCountryStore } from 'hooks/useShippingCountryStore';
import { convertPrice } from 'utils/price';

const propTypes = {
  acceptButtonText: PropTypes.string.isRequired,
  rejectButtonText: PropTypes.string.isRequired,
  shippingText: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  acceptButtonTextColor: PropTypes.string,
  acceptButtonHoverColor: PropTypes.string,
  acceptButtonHoverTextColor: PropTypes.string,
  acceptButtonBorderColor: PropTypes.string,
  acceptButtonHoverBorderColor: PropTypes.string,
  acceptButtonColor: PropTypes.string,
  rejectButtonTextColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  textColor: null,
  backgroundColor: null,
  acceptButtonTextColor: null,
  rejectButtonTextColor: null,
  shippingText: null,
  acceptButtonColor: null,
  acceptButtonHoverColor: null,
  acceptButtonHoverTextColor: null,
  acceptButtonBorderColor: null,
  acceptButtonHoverBorderColor: null,
  headingFont: null,
  textFont: null,
};

export default function UpsellMiniCart({
  shippingText,
  acceptButtonText,
  rejectButtonText,
  textColor,
  backgroundColor,
  acceptButtonTextColor,
  rejectButtonTextColor,
  acceptButtonColor,
  acceptButtonHoverColor,
  acceptButtonHoverTextColor,
  acceptButtonBorderColor,
  acceptButtonHoverBorderColor,
  headingFont,
  textFont,
}) {
  const { preview } = usePreviewData();
  const { cartData } = useFunnelData();
  const lineItem = cartData?.data.lineItems[0];

  const { shippingCountry } = useShippingCountryStore();
  const { symbol: currencySymbol, rate: localFxRate } = useLocalCurrency(shippingCountry);

  // Offer/Directus Product mapping validation
  useEffect(() => {
    if (!lineItem) missingPageOffer(null, preview);
  }, [lineItem, preview]);

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--button-text-color', acceptButtonTextColor, 'var(--text-color-light)')}
          ${buildCssVar(
            '--reject-button-text-color',
            rejectButtonTextColor,
            'var(--text-color-dark)'
          )}
          ${buildCssVar('--button-color', acceptButtonColor, 'var(--primary-color)')}
          ${buildCssVar('--button-hover-color', acceptButtonHoverColor, 'var(--primary-color)')}
          ${buildCssVar(
            '--button-hover-text-color',
            acceptButtonHoverTextColor,
            'var(--text-color-light)'
          )}
          ${buildCssVar(
            '--button-border-color',
            acceptButtonBorderColor,
            acceptButtonColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--button-hover-border-color',
            acceptButtonHoverBorderColor,
            acceptButtonHoverColor,
            'var(--primary-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>
      <div className={styles.box_wrapper}>
        <div className={styles.offer_price_name}>
          <h2 className={styles.product_name}>{lineItem?.name}</h2>
          <div className={styles.price_wrapper}>
            <h2>
              {formatCurrency(
                convertPrice(cartData?.data.total, localFxRate),
                false,
                currencySymbol
              )}
            </h2>
            {shippingText && <h3>{shippingText}</h3>}
          </div>
        </div>
        <div className={styles.button_wrapper}>
          <UpsellForm yesText={acceptButtonText} noText={rejectButtonText} />
        </div>
      </div>
    </div>
  );
}

UpsellMiniCart.propTypes = propTypes;
UpsellMiniCart.defaultProps = defaultProps;
