import Image from './Image';
import styles from './NavBar.module.css';
import PropTypes from 'prop-types';
import { useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const propTypes = {
  logo: PropTypes.object,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        sectionId: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  hideOnMobile: PropTypes.bool,
  linkTextColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  logo: null,
  hideOnMobile: false,
  linkTextColor: null,
  textFont: null,
};

function NavBar({ logo, links, hideOnMobile, linkTextColor, textFont }) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const rootClassNames = ['root', styles.container];
  if (hideOnMobile) rootClassNames.push(styles.hide_on_mobile);

  return (
    <nav className={rootClassNames.join(' ')}>
      <style jsx>{`
        .root {
          ${buildCssVar('--link-text-color', linkTextColor, 'var(--text-color-dark)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.nav_wrapper}>
        <div className={styles.logo_wrapper}>
          <Image alt="Logo" src={logo} priority placeholder="blur" />
        </div>

        <div className={styles.links}>
          {links.map(({ item: { sectionId, text } }) => (
            <a key={sectionId} href={`#${sectionId}`}>
              {text}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
}

NavBar.propTypes = propTypes;
NavBar.defaultProps = defaultProps;

export default NavBar;
