import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { FaAngleUp } from 'react-icons/fa';
import { useTextFontOverrides } from 'utils/font-override';
import styles from './StickyButton.module.css';
import { buildCssVar } from 'utils/style-override';
import { STICKY_BUTTON_ANCHOR_ID } from 'constant';
import { onAddToCart } from 'utils/gtm';
import * as rudder from 'utils/rudderstack';
import { useFunnelData } from 'utils/funnel-data-context';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  buttonRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  buttonText: PropTypes.string.isRequired,
  text: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  textColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  text: undefined,
  buttonColor: null,
  buttonHoverColor: null,
  buttonTextColor: null,
  buttonHoverTextColor: null,
  buttonBorderColor: null,
  buttonHoverBorderColor: null,
  textColor: null,
  textFont: null,
};

function StickyButton({
  buttonRef,
  buttonText,
  text,
  buttonColor,
  buttonHoverColor,
  buttonTextColor,
  buttonHoverTextColor,
  buttonBorderColor,
  buttonHoverBorderColor,
  textColor,
  textFont,
}) {
  const { offerData } = useFunnelData();
  const handleClick = useCallback(() => {
    onAddToCart(offerData);
    rudder.onAddToCart(offerData);
  }, [offerData]);

  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div ref={buttonRef} className={`root ${styles.sticky_btn_section}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--button-color', buttonColor, 'var(--primary-color)')}
          ${buildCssVar('--button-hover-color', buttonHoverColor, 'var(--primary-color)')}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
          ${buildCssVar(
            '--button-hover-text-color',
            buttonHoverTextColor,
            'var(--text-color-light)'
          )}
          ${buildCssVar(
            '--button-border-color',
            buttonBorderColor,
            buttonColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--button-hover-border-color',
            buttonHoverBorderColor,
            buttonHoverColor,
            'var(--primary-color)'
          )}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>
      <Link href={`#${STICKY_BUTTON_ANCHOR_ID}`} legacyBehavior>
        <a className={styles.sticky_btn} onClick={handleClick}>
          <p>{buttonText}</p>
          <div className={styles.sticky_btn_icon}>
            <FaAngleUp />
          </div>
        </a>
      </Link>
      {text && (
        <div className={styles.bottom_text}>
          <MarkdownText text={text} />
        </div>
      )}
    </div>
  );
}

StickyButton.propTypes = propTypes;
StickyButton.defaultProps = defaultProps;

export default StickyButton;
