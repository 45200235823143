import { useEffect, useRef } from 'react';
import styles from './StickyButtonAnchor.module.css';
import { STICKY_BUTTON_ANCHOR_ID } from 'constant';
import { useStickyAnchor } from 'utils/sticky-anchor-context';

export default function StickyButtonAnchor() {
  const anchorRef = useRef();
  const { setAnchorRef } = useStickyAnchor();

  // This hook decides where the sicky button/sticky banner should scrolled to
  useEffect(() => {
    setAnchorRef(anchorRef);

    const nextContainerHeight = anchorRef.current.nextSibling?.offsetHeight;
    anchorRef.current.style = `scroll-margin-top: -${nextContainerHeight - window.innerHeight}px`;
  }, [setAnchorRef]);

  return <div ref={anchorRef} className={styles.container} id={STICKY_BUTTON_ANCHOR_ID}></div>;
}
