import styles from './Banner.module.css';
import PropTypes from 'prop-types';
import { useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const propTypes = {
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  textColor: null,
  backgroundColor: null,
  headingFont: null,
};

export default function Banner({ text, textColor, backgroundColor, headingFont }) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  return (
    <div className={`root ${styles.banner}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-light)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--primary-color)')}
          ${headingFontOverrides ?? ''}
        }
      `}</style>

      <h4>{text}</h4>
    </div>
  );
}

Banner.propTypes = propTypes;
Banner.defaultProps = defaultProps;
