import Image from './Image';
import styles from './HeaderLogo.module.css';
import PropTypes from 'prop-types';
import { buildCssVar } from 'utils/style-override';

const propTypes = {
  image: PropTypes.object.isRequired,
  secondImage: PropTypes.object,
  backgroundColor: PropTypes.string,
};

const defaultProps = {
  secondImage: null,
  backgroundColor: null,
};

export default function HeaderLogo({ image, secondImage, backgroundColor }) {
  return (
    <header className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
        }
      `}</style>
      <div className={styles.header}>
        <div
          className={`${styles.logo} ${
            secondImage ? styles.side_aligned_items : styles.centered_item
          }`}
        >
          <div className={styles.img_wrapper}>
            <Image alt="Logo" src={image} priority placeholder="blur" />
          </div>

          {secondImage && (
            <div className={styles.img_wrapper}>
              <Image alt="Logo" src={secondImage} priority placeholder="blur" />
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

HeaderLogo.propTypes = propTypes;
HeaderLogo.defaultProps = defaultProps;
