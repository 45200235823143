import { createContext, useContext } from 'react';

const StickyAnchorContext = createContext();

const StickyAnchorProvider = ({ stickyAnchorElement, children }) => {
  return (
    <StickyAnchorContext.Provider value={{ ...stickyAnchorElement }}>
      {children}
    </StickyAnchorContext.Provider>
  );
};

const useStickyAnchor = () => {
  const context = useContext(StickyAnchorContext);
  if (context === undefined) {
    throw new Error('useStickyAnchor must be used within a StickyAnchorProvider');
  }

  return context;
};

export { StickyAnchorProvider, useStickyAnchor };
