import styles from './PhotoScrollerBanner.module.css';
import PropTypes from 'prop-types';
import RotatingBanner from './RotatingBanner';
import { useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const propTypes = {
  overlayText: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        image: PropTypes.object.isRequired,
        imageAlt: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  overlayText: null,
  backgroundColor: null,
  textColor: null,
  textFont: null,
};

export default function PhotoScrollerBanner({
  overlayText,
  images,
  backgroundColor,
  textColor,
  textFont,
}) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.main}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {overlayText && <h1 className={styles.overlay_text}>{overlayText}</h1>}
      {images && <RotatingBanner images={images} styles={styles} />}
    </div>
  );
}

PhotoScrollerBanner.propTypes = propTypes;
PhotoScrollerBanner.defaultProps = defaultProps;
