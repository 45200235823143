import PropTypes from 'prop-types';
import styles from './ShowStickyButton.module.css';

const propTypes = {
  showStickyButtonRef: PropTypes.shape({ current: PropTypes.any }),
};

const defaultProps = {
  showStickyButtonRef: null,
};

export default function ShowStickyButton({ showStickyButtonRef }) {
  return <div className={styles.container} ref={showStickyButtonRef}></div>;
}

ShowStickyButton.propTypes = propTypes;
ShowStickyButton.defaultProps = defaultProps;
