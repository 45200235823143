import Image from './Image';
import useWindowDimensions from 'utils/useWindowDimensions';
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        image: PropTypes.object.isRequired,
        imageAlt: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  styles: PropTypes.object.isRequired,
};

const defaultProps = {};

function RotatingBanner({ images, styles }) {
  const { width } = useWindowDimensions();
  const [bannerCount, setBannerCount] = useState(0);

  useEffect(() => {
    // 180 is the image width plus its horizontal margins specified in css module for each image
    setBannerCount(Math.ceil(width / (images.length * 180)));
  }, [width, images.length]);

  // A wrapper around all the images coming from Directus
  const WrappedImages = useMemo(() => {
    return images.map(({ item }, index) => {
      return (
        <div className={styles.image_wrapper} key={index}>
          <Image
            src={item.image}
            alt={item.imageAlt}
            priority
            placeholder="blur"
            width={180}
            height={180}
            sizes="180px"
          />
        </div>
      );
    });
  }, [images, styles.image_wrapper]);

  // A Banner is a set of 15 wrapped images from directus;
  // the reason why there are 15 of them is that there should
  // be enough sets of images to fill the width of all the screen sizes
  const Banner = useMemo(() => {
    if (bannerCount) {
      return [...Array(bannerCount)].map((wrappedImages, wrappedImagesIndex) => {
        return (
          <div key={wrappedImagesIndex} className={styles.images_wrapper}>
            {WrappedImages}
          </div>
        );
      });
    }
  }, [WrappedImages, bannerCount, styles.images_wrapper]);

  return (
    <div className={styles.banner_sets_wrapper}>
      {/** Two sets of Banner to make sure the second set will be shown when the first set gets to its final position (which is -100% horizontally) */}
      {[...Array(2)].map((banner, bannerIndex) => {
        return (
          <div className={styles.container} key={bannerIndex}>
            {Banner}
          </div>
        );
      })}
    </div>
  );
}

RotatingBanner.propTypes = propTypes;
RotatingBanner.defaultProps = defaultProps;

export default RotatingBanner;
