import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Image from './Image';
import styles from './Byline.module.css';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  image: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired,
  text: PropTypes.string,
  textColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  text: undefined,
  textColor: null,
  headingFont: null,
  textFont: null,
};

export default function Byline({ image, imageAlt, text, textColor, headingFont, textFont }) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.image_container}>
        <div className={styles.image_wrapper}>
          <Image src={image} alt={imageAlt} placeholder="blur" />
        </div>
        {text && (
          <div className={styles.text}>
            <MarkdownText text={text} />
          </div>
        )}
      </div>
    </div>
  );
}

Byline.propTypes = propTypes;
Byline.defaultProps = defaultProps;
