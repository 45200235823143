import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Image from './Image';
import { useTextFontOverrides } from 'utils/font-override';
import styles from './StickyBanner.module.css';
import { useCallback, useEffect, useRef } from 'react';
import { buildCssVar } from 'utils/style-override';
import { useStickyAnchor } from 'utils/sticky-anchor-context';
import { onAddToCart } from 'utils/gtm';
import * as rudder from 'utils/rudderstack';
import { useFunnelData } from 'utils/funnel-data-context';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  sectionId: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  textColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
  backgroundColor: PropTypes.string,
};

const defaultProps = {
  text: undefined,
  image: null,
  imageAlt: null,
  buttonColor: null,
  buttonHoverColor: null,
  buttonTextColor: null,
  buttonHoverTextColor: null,
  buttonBorderColor: null,
  buttonHoverBorderColor: null,
  textColor: null,
  textFont: null,
  backgroundColor: null,
};

function StickyBanner({
  buttonText,
  text,
  formattedText,
  image,
  imageAlt,
  buttonColor,
  buttonHoverColor,
  buttonTextColor,
  buttonHoverTextColor,
  buttonBorderColor,
  buttonHoverBorderColor,
  textColor,
  textFont,
  backgroundColor,
  sectionId,
}) {
  const stickyBannerRef = useRef();
  const { anchorRef } = useStickyAnchor();

  useEffect(() => {
    const stickyAnchorElement = anchorRef?.current?.nextSibling;
    const stickyAnchorElementTop = stickyAnchorElement?.getBoundingClientRect().top;

    let stickyAnchorObserver;
    if (stickyAnchorElement) {
      if ('IntersectionObserver' in window) {
        stickyAnchorObserver = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              stickyBannerRef.current.classList.toggle(
                styles.show,
                entry.boundingClientRect.top >= 0 || entry.boundingClientRect.bottom <= 0
              );
            });
          },
          {
            threshold: [...Array(20).keys()].map(x => x / 20),
            rootMargin: `${stickyAnchorElementTop}px`,
          }
        );
        stickyAnchorObserver.observe(stickyAnchorElement);
      }
    }

    return () =>
      stickyAnchorObserver &&
      stickyAnchorElement &&
      stickyAnchorObserver.unobserve(stickyAnchorElement);
  }, [anchorRef]);

  const { offerData } = useFunnelData();
  const handleClick = useCallback(() => {
    onAddToCart(offerData);
    rudder.onAddToCart(offerData);
  }, [offerData]);

  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div ref={stickyBannerRef} id="sticky-banner" className={`root ${styles.section}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--button-color', buttonColor, 'var(--primary-color)')}
          ${buildCssVar('--button-hover-color', buttonHoverColor, 'var(--primary-color)')}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
          ${buildCssVar(
            '--button-hover-text-color',
            buttonHoverTextColor,
            'var(--text-color-light)'
          )}
          ${buildCssVar(
            '--button-border-color',
            buttonBorderColor,
            buttonColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--button-hover-border-color',
            buttonHoverBorderColor,
            buttonHoverColor,
            'var(--primary-color)'
          )}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>
      <div className={styles.container}>
        <div className={styles.column}>
          {image && (
            <div className={styles.image_wrapper}>
              <Image src={image} alt={imageAlt} priority placeholder="blur" />
            </div>
          )}

          {text && (
            <div className={styles.text}>
              {!formattedText && <MarkdownText text={text}></MarkdownText>}
              {formattedText && <div dangerouslySetInnerHTML={{ __html: formattedText }} />}
            </div>
          )}
        </div>

        <a className={styles.btn} onClick={handleClick} href={`#${sectionId}`}>
          {buttonText}
        </a>
      </div>
    </div>
  );
}

StickyBanner.propTypes = propTypes;
StickyBanner.defaultProps = defaultProps;

export default StickyBanner;
