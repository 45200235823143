import PropTypes from 'prop-types';
import styles from './Embed.module.css';

const propTypes = {
  code: PropTypes.string.isRequired,
};

const defaultProps = {};

export default function Embed({ code }) {
  return <div className={styles.container} dangerouslySetInnerHTML={{ __html: code }} />;
}

Embed.propTypes = propTypes;
Embed.defaultProps = defaultProps;
