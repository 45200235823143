import PropTypes from 'prop-types';
import styles from './LogosBanner.module.css';
import RatingStars from './RatingStars';
import RotatingBanner from './RotatingBanner';
import { useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const propTypes = {
  overlayText: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        image: PropTypes.object.isRequired,
        imageAlt: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  starColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  overlayText: null,
  starColor: null,
  backgroundColor: null,
  textColor: null,
  textFont: null,
};

function LogosBanner({ overlayText, images, starColor, backgroundColor, textColor, textFont }) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.main}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.overlay_text_container}>
        <div className={styles.overlay_text_wrapper}>
          <div className={styles.stars_wrapper}>
            <RatingStars rating={5} starColor={starColor} className={styles.stars} />
          </div>
          {overlayText && (
            <div className={styles.overlay_text}>
              <p>{overlayText}</p>
            </div>
          )}
        </div>
      </div>
      {images && <RotatingBanner images={images} styles={styles} />}
    </div>
  );
}

LogosBanner.propTypes = propTypes;
LogosBanner.defaultProps = defaultProps;

export default LogosBanner;
